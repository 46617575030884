<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Kas / Bank</h4>
      <span class="text-danger">Coming soon</span>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('setPageTitle', 'Kas / Bank')
  }
}
</script>

<style scoped>

</style>